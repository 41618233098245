<template>
  <main class="content-1400 page-content">
    <div class="pad-16" />
    <div class="grid-12">
      <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
        <section class="br-10" style="background-image: url('assets/images/hamburg.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center center;">
          <div class="pad-80" />
          <div class="pad-40" />
        </section>
      </div>
      <div class="col-sp-12 col-sd-6 row-sp-12 pad-8">
        <div class="bgcolor-primary br-10 pad-24">
          <h2 class="color-white mar-bottom-12 f-normal">
            Hey {{ user.firstname }},
          </h2>
          <p class="color-white">
            Herzlich willkommen im Mendo-Verwaltungsportal! Schön, dass du Teil unseres Teams bist und dich um die Portalverwaltung auf unserer Plattform kümmerst.
            <br><br>
            Hier hast du die Möglichkeit, Tarife, Benutzer und Portale zu verwalten, Bestellungen zu sichten und zu bearbeiten.
            <br><br>
            Sollten Technische Probleme auftreten, so wende dich bitte an <a href="mailto:hello@20north.de" style="text-decoration: underline; color: #ffffff;">hello@20north.de</a>.
          </p>
          <div class="pad-8" />
        </div>
      </div>
      <div class="col-sp-12 row-sp-12 pad-8 mar-top-16">
        <h4>Kundenportale</h4>
      </div>
      <div class="col-sp-12 col-tb-6 col-ld-4 row-sp-12 pad-8" v-for="portal in portale" :key="portal.id">
        <div class="bgcolor-white br-10 pad-16">
          <div style="clear: both;">
            <div style="float: right;">
              <router-link :to="'kundenportale/edit/'+portal.id" class="tiny button">
                <svg width="16px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="m402.3 344.9 32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174 402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" fill="#666666" class="fill-000000" /></svg>
              </router-link>
            </div>
          </div>
          <div class="grid-16">
            <div class="col-sp-4 row-sp-16 pad-8" style="align-items: center;display: grid;">
              <img class="responsive-img" :src="'assets/images/portale/'+portal.company_logo" style="max-height: 50px;width: auto;">
            </div>
            <div class="col-sp-12 row-sp-16">
              <div class="pad-8 mar-top-12 mar-left-12">
                <h6 class="f-normal">
                  {{ portal.company_name }}
                </h6>
                <a style="text-decoration: none; font-size: 0.8em;" class="color-primary" target="_blank" :href="portal.portal_domain">
                  {{ portal.portal_domain }}
                </a>
              </div>
            </div>
          </div>
          <div class="pad-8">
            <router-link class="button primary fluid" :to="'/kundenportale/orders/'+portal.id">
              Bestellungen
            </router-link>
            <div class="pad-4" />
            <router-link class="button black fluid" :to="'/kundenportale/usermanagement/'+portal.id">
              Usermanagement
            </router-link>
            <div class="pad-4" />
            <a style="font-size: 0.7em;" class="button fluid black" target="_blank" :href="portal.portal_domain+'?email='+user.email+'&firstname='+user.firstname+'&lastname='+user.lastname+'&key='+portal.externLoginKey">
              Als {{ user.firstname }} {{ user.lastname }} einloggen
            </a>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "dashboardView",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      portale: ''
    }
  },
  mounted() {
    this.getPortale()
  },
  methods: {
    getPortale(){
      aSyncData('kundenportale/getPortale')
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.portale = r.data
            }
          })
          .catch(e => {console.log(e)})
    }
  }
}
</script>

<style scoped>

</style>