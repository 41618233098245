<template>
  <main class="content-1400 userManagement page-content">
    <div class="grid-12 pad-4">
      <div class="col-sp-12 col-tb-8 row-sp-12 pad-8">
        <h5 class="color-primary f-normal">
          Übersicht
        </h5>
        <h1>Nutzerverwaltung</h1>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12 pad-8 t-right pad-top-16">
        <div class="field simple">
          <input id="search" placeholder="Suche..." name="search" type="search" v-model="searchString">
        </div>
      </div>
    </div>
    <div class="grid-12">
      <div class="col-sp-12 col-sd-8 row-sp-12">
        <div class="grid-12 pad-8">
          <div class="col-sp-12 col-tb-6 col-ld-4 row-sp-12 pad-4 " v-for="nutzer in nutzerlisFiltered" :key="nutzer.id">
            <div class="bgcolor-secondary pad-8" style="border-top-right-radius: 8px; border-top-left-radius: 8px;">
              <div class="t-center">
                <img style="margin-bottom: -60px;" class="width-100 circular image pad-8" :src="'assets/images/users/'+nutzer.avatar">
              </div>
            </div>
            <div class="bgcolor-white br-10 pad-8">
              <div style="clear: both">
                <div style="float: right">
                  <a class="button" :href="'/nutzerverwaltung/edit/'+nutzer.id">
                    <svg width="16px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="M402.3 344.9l32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174L402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7l-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" /></svg>
                  </a>
                </div>
              </div>
              <div class="t-center">
                <div class="pad-8 mar-top-32">
                  <h5 style="font-size: 0.9em;">
                    {{ nutzer.firstname }} {{ nutzer.lastname }}
                  </h5>
                  <h6 class="color-primary f-light" style="font-size: 0.9em; margin-top: -4px;">
                    <span style="font-size: 0.8em;" v-if="nutzer.role == 1">Administrator</span>
                    <span style="font-size: 0.8em;" v-else>Mitarbeiter</span>
                  </h6>
                  <a style="text-decoration: none;" class="color-primary" href="mailto:tommy@20north.de">
                    <svg style="vertical-align: -6px;" width="20px" viewBox="0 0 64 64" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64"><path d="m-22.5-213.2-1.9-1.9-17.6 17.6c-2.2 2.2-5.9 2.2-8.1 0L-67.7-215l-1.9 1.9 13.1 13.1-13.1 13.1 1.9 1.9 13.1-13.1 2.6 2.6c1.6 1.6 3.7 2.5 5.9 2.5s4.3-.9 5.9-2.5l2.6-2.6 13.1 13.1 1.9-1.9-13.1-13.1 13.2-13.2" transform="translate(78 232)" fill="#000000" class="fill-134563" /><path d="M-26.2-181.6h-39.5c-2.3 0-4.2-1.9-4.2-4.2V-214c0-2.3 1.9-4.2 4.2-4.2h39.5c2.3 0 4.2 1.9 4.2 4.2v28.2c0 2.3-1.9 4.2-4.2 4.2zm-39.6-33.9c-.8 0-1.4.6-1.4 1.4v28.2c0 .8.6 1.4 1.4 1.4h39.5c.8 0 1.4-.6 1.4-1.4v-28.2c0-.8-.6-1.4-1.4-1.4h-39.5z" transform="translate(78 232)" fill="#000000" class="fill-134563" /></svg>
                    <span style="font-size: 0.8em;" class="mar-left-4">{{ nutzer.email }}</span>
                  </a>
                </div>
              </div>
              <div class="col-sp-16 col-tb-1 row-sp-16 pad-8 t-right" />
            </div>
          </div>
        </div>
        <div class="t-center" v-if="!nutzerlisFiltered.length">
          Keine Nutzer gefunden
        </div>
      </div>
      <div class="col-sp-12 col-sd-4 row-sp-12">
        <div class="bgcolor-white br-10 mar-top-12">
          <div class="pad-8">
            <div class="t-center pad-8">
              <h5>Offene Einladungen</h5>
            </div>
          </div>
          <div class="grid-12 pad-8">
            <div class="col-sp-12 row-sp-12">
              <div v-for="invitili in invitilisFiltered" :key="invitili.id">
                <div class="grid-12 pad-8 br-10" style="background-color: #00000008">
                  <div class="col-sp-12 row-sp-12 pad-4">
                    <div>
                      <b>{{ invitili.name }}</b> (<a class="color-primary">{{ invitili.email }}</a>)
                    </div>
                  </div>
                  <div class="col-sp-8 row-sp-12 pad-4">
                    <span class="label tiny">
                      Eingeladen am: {{ formatDate(invitili.created) }}
                    </span>
                  </div>
                  <div class="col-sp-4 row-sp-12 pad-4" style="align-self: center; text-align: right">
                    <div>
                      <button @click="revertId = invitili.id; revertModal = true" class="button tiny primary">
                        Zurückziehen
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="t-center" v-if="!invitilisFiltered.length">
            Keine Einladungen gefunden
          </div>
          <div class="pad-16">
            <button @click="einladungsModel = true" class="button fluid primary">
              Nutzer Einladen
            </button>
          </div>
        </div>
      </div>
    </div>

    <Vue-Modal :show="einladungsModel" approve="Einladen" @approve="invite" @close="einladungsModel = false" @cancel="einladungsModel = false">
      <div v-if="!loading">
        <div class="t-right">
          <a @click="einladungsModel = false" style="cursor: pointer;">
            <svg enable-background="new 0 0 128 128" id="Layer_1" version="1.1" viewBox="0 0 128 128" width="32px" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><g><path d="M84.815,43.399c-0.781-0.782-2.047-0.782-2.828,0L64.032,61.356L46.077,43.399c-0.781-0.782-2.047-0.782-2.828,0    c-0.781,0.781-0.781,2.047,0,2.828l17.955,17.957L43.249,82.141c-0.781,0.78-0.781,2.047,0,2.828    c0.391,0.39,0.902,0.585,1.414,0.585s1.023-0.195,1.414-0.585l17.955-17.956l17.955,17.956c0.391,0.39,0.902,0.585,1.414,0.585    s1.023-0.195,1.414-0.585c0.781-0.781,0.781-2.048,0-2.828L66.86,64.184l17.955-17.957C85.597,45.447,85.597,44.18,84.815,43.399z     M64.032,14.054c-27.642,0-50.129,22.487-50.129,50.127c0.002,27.643,22.491,50.131,50.133,50.131    c27.639,0,50.125-22.489,50.125-50.131C114.161,36.541,91.674,14.054,64.032,14.054z M64.036,110.313h-0.002    c-25.435,0-46.129-20.695-46.131-46.131c0-25.435,20.693-46.127,46.129-46.127s46.129,20.693,46.129,46.127    C110.161,89.617,89.47,110.313,64.036,110.313z" /></g></g></svg>
          </a>
        </div>
        <h5>
          Nutzer einladen
        </h5>
        <div class="field mar-bottom-8">
          <label>Name:</label>
          <input class="inputModal" type="text" v-model="name" placeholder="Name">
        </div>
        <div class="field">
          <label>E-Mail-Adresse:</label>
          <input class="inputModal" type="email" v-model="email" placeholder="E-Mail-Adresse">
        </div>
      </div>
      <loader v-else />
    </Vue-Modal>
    <Vue-Modal :show="deleteModal" approve="Löschen" cancel="Abbrechen" @approve="deleteUser" @close="deleteModal = false" @cancel="deleteModal = false">
      <div v-if="!loading">
        Möchtest du den Nutzer wirklich endgültig löschen?
      </div>
      <loader v-else />
    </Vue-Modal>
    <Vue-Modal :show="revertModal" approve="Zurückziehen" cancel="Abbrechen" @approve="revert" @close="revertModal = false" @cancel="revertModal = false">
      <div v-if="!loading">
        Möchtest du die Einladung zurückziehen?
      </div>
      <loader v-else />
    </Vue-Modal>
  </main>
</template>

<script>
import {aSyncData, dateToString, stringToDate} from "@/modules/main";
import Loader from "@/components/basic/loader";

export default {
  name: "Overview",
  components: {Loader},
  title: "Nutzerverwaltung",
  data(){
    return {
      searchString: '',
      nutzerlis: [],
      invitilis: [],
      einladungsModel: false,
      email: '',
      name: '',
      loading: false,
      deleteModal: false,
      revertModal: false,
      deleteId: null,
      revertId: null
    }
  },
  computed:  {
    nutzerlisFiltered(){
      return this.nutzerlis.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
    invitilisFiltered(){
      return this.invitilis.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    }
  },
  mounted() {
    this.loadNutzerlis()
  },
  methods: {
    loadNutzerlis(){
      aSyncData('nutzerverwaltung/getAllNutzerlis')
          .then(r => {
            if(r.status === 250) {
              this.nutzerlis = r.data.nutzerlis
              this.invitilis = r.data.invitilis
            }
          })
    },
    invite(){
      this.loading = true
      aSyncData('nutzerverwaltung/invite', {email: this.email, name: this.name})
          .then(r => {
            if(r.status === 250){
              this.einladungsModel = false
              this.name = ''
              this.email = ''
              this.loadNutzerlis()
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    deleteUser(){
      this.loading = true
      aSyncData('nutzerverwaltung/deleteUser', {userId: this.deleteId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.nutzerlis = this.nutzerlis.filter(i => i.id !== this.deleteId)
              this.deleteId = null
              this.deleteModal = false
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    revert(){
      this.loading = true
      aSyncData('nutzerverwaltung/revert', {inviteId: this.revertId})
          .then(r => {
            console.log(r)
            if(r.status === 250){
              this.invitilis = this.invitilis.filter(i => i.id !== this.revertId)
              this.revertId = null
              this.revertModal = false
            }
          })
          .catch(e => {
            console.log(e)
          })
          .finally(() => {this.loading = false})
    },
    formatDate(string){
      return dateToString(stringToDate(string))
    }
  }
}
</script>

<style scoped>

</style>