<template>
  <main class="content-1400 page-content">
    <div id="page-dashboard">
      <div class="pad-4" />
      <div class="content-1200">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12 t-left pad-8">
            <router-link :to="'/kundenportale/edit/'+$route.params.id" class="button primary">
              <i class="arrow left icon" />Zurück zur Übersicht
            </router-link>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right pad-8" />
        </div>
        <div class="pad-8">
          <div class="bgcolor-white br-10 pad-24">
            <div class="grid-16">
              <div class="col-sp-4 col-tb-2 row-sp-16 pad-8 pad-right-16 t-center">
                <img style="max-height: 55px; width: auto;" class="responsive-img pad-8" :src="'assets/images/portale/'+portal.company_logo">
              </div>
              <div class="col-sp-12 col-tb-8 row-sp-16 pad-16">
                <h2 class="ui header mar-left-12">
                  {{ portal.company_name }}
                  <div class="color-primary">
                    Nutzerübersicht
                  </div>
                </h2>
              </div>
              <div class="col-sp-16 col-tb-6 row-sp-16 t-right pad-top-16">
                <div class="field">
                  <input type="search" placeholder="Suche.." v-model="searchString">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-16 pad-8" id="orders">
          <div class="col-sp-16 row-sp-16 pad-4">
            <div class="bgcolor-white pad-24 br-10">
              <div v-if="status == 'ok'" class="pad-8 t-center">
                <span class="label green">Änderungen erfolgreich gespeichert</span>
              </div>
              <div v-if="!usersFiltered.length" class="t-center pad-16">
                <p>Keine Benutzer gefunden</p>
              </div>
              <div v-for="user in usersFiltered" :key="user.id" class="grid-12 pad-4 pad-top-8 mar-top-4 pad-bottom-8 br-10" style="background-color: #00000007;">
                <div class="col-sp-12 col-sd-4 row-sp-12 pad-left-8 pad-top-4">
                  <p>
                    <span v-if="user.role == 0" class="button black tiny">
                      Normaler Nutzer
                    </span>
                    <span v-else class="button black tiny">
                      Genehmiger_in
                    </span>
                  </p>
                  <p>
                    <span v-if="user.lastname == null && user.firstname == null">Keine Angabe</span>
                    <span v-else>{{ user.firstname }} {{ user.lastname }}</span>
                  </p>
                  <p class="color-primary" style="margin-top: -2px;">
                    {{ user.email }}
                  </p>
                </div>
                <div class="col-sp-12 col-sd-5 row-sp-12">
                  <p style="margin-top: -2px;" v-if="user.role == 1 && (user.superadmin == 1 || user.no_kst_assignment == 1 || (user.kostenstelle != '' && user.kostenstelle != null))">
                    <b style="display: block; font-size: 0.9em;">
                      Neugeräte
                    </b>

                    <span style="font-size: 0.9em; display: block;" v-if="user.superadmin == 1">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Supergenehmiger_in
                    </span>
                    <span style="font-size: 0.9em; display: block;" v-if="user.no_kst_assignment == 1">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Ohne Zuordnung
                    </span>
                    <span style="font-size: 0.9em; display: block;" v-if="user.kostenstelle != '' && user.kostenstelle != null">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Zugeord. Kostenstelle
                    </span>
                  </p>
                  <p v-if="user.role == 1 && (user.superadmin_austausch == 1 || user.no_kst_assignment_austausch == 1 || (user.kostenstelle_austausch != '' && user.kostenstelle_austausch != null))">
                    <b style="display: block; font-size: 0.9em;">
                      Ausstauschgeräte
                    </b>
                    <span style="font-size: 0.9em; display: block;" v-if="user.superadmin_austausch == 1">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Supergenehmiger_in
                    </span>
                    <span style="font-size: 0.9em; display: block;" v-if="user.no_kst_assignment_austausch == 1">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Ohne Zuordnung
                    </span>
                    <span style="font-size: 0.9em; display: block;" v-if="user.kostenstelle_austausch != '' && user.kostenstelle_austausch != null">
                      <svg width="16px" style="vertical-align: -2px;" class="mar-right-4" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12 1a11 11 0 1 0 11 11A11 11 0 0 0 12 1Zm5.707 8.707-7 7a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L10 14.586l6.293-6.293a1 1 0 0 1 1.414 1.414Z" fill="#666666" class="fill-232323" /></svg>
                      Zugeord. Kostenstelle
                    </span>
                  </p>
                </div>
                <div class="col-sp-12 col-sd-3 row-sp-12 t-right pad-top-8 pad-right-8">
                  <button @click="getUserFromPortalById(user.id)" class="button fluid tiny black mar-bottom-8">
                    <svg style="vertical-align: -2px;" width="16px" viewBox="0 0 576 512" xmlns="http://www.w3.org/2000/svg"><path d="m402.3 344.9 32-32c5-5 13.7-1.5 13.7 5.7V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h273.5c7.1 0 10.7 8.6 5.7 13.7l-32 32c-1.5 1.5-3.5 2.3-5.7 2.3H48v352h352V350.5c0-2.1.8-4.1 2.3-5.6zm156.6-201.8L296.3 405.7l-90.4 10c-26.2 2.9-48.5-19.2-45.6-45.6l10-90.4L432.9 17.1c22.9-22.9 59.9-22.9 82.7 0l43.2 43.2c22.9 22.9 22.9 60 .1 82.8zM460.1 174 402 115.9 216.2 301.8l-7.3 65.3 65.3-7.3L460.1 174zm64.8-79.7-43.2-43.2c-4.1-4.1-10.8-4.1-14.8 0L436 82l58.1 58.1 30.9-30.9c4-4.2 4-10.8-.1-14.9z" fill="#ffffff" class="fill-000000" /></svg>
                    Bearbeiten
                  </button>
                  <a style="font-size: 0.7em;" class="button fluid primary tiny" target="_blank" :href="portal.portal_domain+'?email='+user.email+'&firstname='+user.firstname+'&lastname='+user.lastname+'&key='+portal.externLoginKey">
                    <svg style="vertical-align: -2px;" width="16px" id="Layer_1" version="1.1" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24"><path d="M2 12h14.4M12.1.8h7.4C20.9.8 22 1.9 22 3.3v17.5c0 1.4-1.1 2.5-2.5 2.5h-7.4" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" /><path d="m12.6 15.8 3.8-3.8-3.8-3.8" fill="none" stroke="#ffffff" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" class="stroke-000000" /></svg>
                    Einloggen
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <transition name="modal" id="UserDetailsModal" style="position: fixed;">
    <div class="vueModal" v-if="UserDetailsModal">
      <div class="vueModalOuter">
        <div class="vueModalInner">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Benutzer bearbeiten
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div class="grid-12">
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div class="field">
                  <label>Vorname:</label>
                  <input type="text" placeholder="Vorname" name="firstname" v-model="userDetails.firstname">
                </div>
              </div>
              <div class="col-sp-12 col-sd-6 row-sp-12 pad-4">
                <div class="field">
                  <label>Nachname:</label>
                  <input type="text" placeholder="Nachname" name="lastname" v-model="userDetails.lastname">
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label>E-Mail:</label>
                  <input type="text" placeholder="E-Mail" name="email" v-model="userDetails.email">
                </div>
              </div>
              <div v-if="portal.password_login" class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label>Neues Passwort:</label>
                  <input type="password" placeholder="Neues Passwort" name="password">
                </div>
              </div>
              <div v-else class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label>Neues Passwort:</label>
                  <span class="label tiny">
                    Das Passwort kann nur über das AD des Kunden bearbeitet werden.
                  </span>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="field">
                  <label>Benutzerrolle:</label>
                  <select name="role" v-model="userDetails.role">
                    <option value="0">
                      Normaler Nutzer
                    </option>
                    <option value="1">
                      Kostenstellengenehmiger_in
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4" v-if="userDetails.role == 1">
                <div class="bgcolor-ligthgrey br-10 pad-16 mar-bottom-16">
                  <h6 class="t-center">
                    Rechte für Neugeräte
                  </h6>
                  <div class="content">
                    <div class="pad-8" />
                    <div class="field">
                      <input id="superadmin" type="checkbox" name="superadmin" true-value="1" false-value="0" v-model="userDetails.superadmin">
                      <label style="display: inline-block;" class="mar-left-8 mar-right-8" for="superadmin">
                        Supergenehmiger_in
                        <i style="font-size: 0.8em;" class="f-normal">(Kann alle Bestellungen für Neugeräte bearbeiten)</i>
                      </label>
                    </div>
                    <div class="pad-8 t-center">
                      - oder -
                    </div>
                    <div class="field">
                      <label>Kostenstelle(n) mit Komma getrennt:</label>
                      <textarea placeholder="Kostenstelle(n) mit Komma getrennt" name="kostenstelle" v-model="userDetails.kostenstelle" />
                    </div>
                    <div class="field">
                      <input id="no_kst_assignment" type="checkbox" name="no_kst_assignment" true-value="1" false-value="0" v-model="userDetails.no_kst_assignment">
                      <label style="display: inline-block;" class="mar-left-8 mar-right-8" for="no_kst_assignment">
                        Ohne Zuordnung
                        <i style="font-size: 0.8em;" class="f-normal">(Kann Bestellungen ohne Zuordnung bearbeiten)</i>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="bgcolor-ligthgrey br-10 pad-16 mar-bottom-16">
                  <h6 class="t-center">
                    Rechte für Ausstauschgeräte
                  </h6>
                  <div class="content">
                    <div class="pad-8" />
                    <div class="field">
                      <input true-value="1" false-value="0" id="superadmin_austausch" type="checkbox" name="superadmin_austausch" v-model="userDetails.superadmin_austausch">
                      <label style="display: inline-block;" class="mar-left-8 mar-right-8" for="superadmin_austausch">
                        Supergenehmiger_in
                        <i style="font-size: 0.8em;" class="f-normal">(Kann alle Bestellungen für Ausstauschgeräte bearbeiten)</i>
                      </label>
                    </div>
                    <div class="pad-8 t-center">
                      - oder -
                    </div>
                    <div class="field">
                      <label>Kostenstelle(n) mit Komma getrennt:</label>
                      <textarea placeholder="Kostenstelle(n) mit Komma getrennt" name="kostenstelle_austausch" v-model="userDetails.kostenstelle_austausch" />
                    </div>
                    <div class="field">
                      <input id="no_kst_assignment_austausch" type="checkbox" name="no_kst_assignment_austausch" true-value="1" false-value="0" v-model="userDetails.no_kst_assignment_austausch">
                      <label style="display: inline-block;" class="mar-left-8 mar-right-8" for="no_kst_assignment_austausch">
                        Ohne Zuordnung
                        <i style="font-size: 0.8em;" class="f-normal">(Kann Bestellungen ohne Zuordnung bearbeiten)</i>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-sp-12 row-sp-12 pad-4">
                <div class="pad-8" />
                <button class="button primary fluid" @click="saveUser">
                  Speichern
                </button>
              </div>
            </div>
          </div>
          <div class="pad-4">
            <button class="button black fluid" @click="UserDetailsModal = false">
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "usermanagement",
  title: "Portal Users",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      portal: '',
      users: [],
      searchString: '',
      UserDetailsModal: false,
      userDetails: '',
      password: '',
      status: ''
    }
  },
  computed:  {
    usersFiltered(){
      return this.users.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
  },
  mounted() {
    this.config.loading = false
    this.getPortalById()
    this.getUsers()
  },
  methods: {
    getUsers(){
      this.users = [];
      aSyncData('kundenportale/getUsers', {id: this.$route.params.id})
          .then(r => {
            if(r.status === 250) {
              this.users = r.data
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsDate[1]
    },
    getPortalById() {
      aSyncData('kundenportale/getPortalById', {id: this.$route.params.id})
          .then(r => {
            if(r.status === 250) {
              this.portal = r.data
            }
          })
    },
    getUserFromPortalById(userid){
      this.userDetails = '';
      this.UserDetailsModal = true;
      aSyncData('kundenportale/getUserFromPortalById', {id: this.$route.params.id, userid: userid})
          .then(r => {
            if(r.status === 250) {
              console.log(r.data)
              this.userDetails = r.data
            }
          })
    },
    saveUser(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('userId', this.userDetails.id)
      fD.append('firstname', this.userDetails.firstname)
      fD.append('lastname', this.userDetails.lastname)
      fD.append('email', this.userDetails.email)
      fD.append('kostenstelle', this.userDetails.kostenstelle)
      fD.append('kostenstelle_austausch', this.userDetails.kostenstelle_austausch)
      fD.append('no_kst_assignment', this.userDetails.no_kst_assignment)
      fD.append('no_kst_assignment_austausch', this.userDetails.no_kst_assignment_austausch)
      fD.append('superadmin', this.userDetails.superadmin)
      fD.append('superadmin_austausch', this.userDetails.superadmin_austausch)
      fD.append('role', this.userDetails.role)
      fD.append('password', this.password)
      fD.append('id', this.$route.params.id)
      this.axios.post('kundenportale/editPortalUser', fD)
          .then(response => {
            if(response.status === 250){
              this.status = 'ok'
              _this.UserDetailsModal = false;
              _this.getUsers()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })



    }
  }
}
</script>

<style scoped>
#orders h6{
  font-size: 0.85em;
}
#orders p,
#orders a{
  font-size: 0.9em;
  margin-top: 4px;
}
</style>