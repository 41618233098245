<template>
  <main class="content-1400 page-content">
    <div id="page-dashboard">
      <div class="pad-4" />
      <div class="content-1200">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12 t-left pad-8">
            <router-link :to="'/kundenportale/edit/'+$route.params.id" class="button primary">
              <i class="arrow left icon" />Zurück zur Übersicht
            </router-link>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right pad-8" />
        </div>
        <div class="pad-8">
          <div class="bgcolor-white br-10 pad-24">
            <div class="grid-16">
              <div class="col-sp-4 col-tb-2 row-sp-16 pad-8 pad-right-16 t-center">
                <img style="max-height: 55px; width: auto;" class="responsive-img pad-8" :src="'assets/images/portale/'+portal.company_logo">
              </div>
              <div class="col-sp-12 col-tb-8 row-sp-16 pad-16">
                <h2 class="ui header mar-left-12">
                  {{ portal.company_name }}
                  <div class="color-primary" v-if="mendoDelete == 0">
                    Bestellungen
                  </div>
                  <div class="color-primary" v-else>
                    Gelöschte Bestellungen
                  </div>
                </h2>
              </div>
              <div class="col-sp-16 col-tb-6 row-sp-16 t-right pad-top-16">
                <div class="field">
                  <input type="search" placeholder="Suche.." v-model="searchString">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-16 pad-8" id="orders">
          <div class="col-sp-16 row-sp-16 pad-4">
            <div class="bgcolor-white pad-24 br-10">
              <div class="t-right">
                <button v-if="mendoDelete == 0" class="button tiny" @click="mendoDelete = 1; getOrders();">
                  Gelöschte Bestellungen anzeigen
                </button>
                <button v-else class="button tiny" @click="mendoDelete = 0; getOrders();">
                  Aktive Bestellungen anzeigen
                </button>
              </div>
              <div class="grid-12 pad-4">
                <div class="col-sp-12 col-tb-1 row-sp-12">
                  <h6 class="f-normal">
                    ID
                  </h6>
                </div>
                <div class="col-sp-12 col-tb-2 row-sp-12">
                  <h6 class="f-normal">
                    Status
                  </h6>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12">
                  <h6 class="f-normal">
                    E-Mail-Adressen
                  </h6>
                </div>
                <div class="col-sp-12 col-tb-5 row-sp-12">
                  <h6 class="f-normal">
                    Zeitstempel
                  </h6>
                </div>
              </div>
              <div v-if="!ordersFiltered.length" class="t-center pad-16">
                <p>Keine Bestellungen gefunden</p>
              </div>
              <div v-for="order in ordersFiltered" :key="order.id" class="grid-12 pad-4 pad-top-8 mar-top-4 pad-bottom-8 br-10" style="background-color: #00000007;">
                <div class="col-sp-12 col-tb-6 col-sd-1 row-sp-12 pad-top-16">
                  <p class="pad-left-4">
                    #{{ order.id }}
                  </p>
                </div>
                <div class="col-sp-12 col-tb-6 col-sd-2 row-sp-12 pad-top-16">
                  <span v-if="order.status == 0" class="label tiny yellow">
                    In Freigabe
                  </span>
                  <span v-else-if="order.status == 1" class="label tiny green">
                    Freigegeben
                  </span>
                  <span v-else-if="order.status == 2" class="label tiny red">
                    Abgelehnt
                  </span>
                </div>
                <div class="col-sp-12 col-sd-4 row-sp-12">
                  <p>
                    Besteller: <a class="color-primary">{{ order.contactemail }}</a><br>
                    Nutzer: <a class="color-primary">{{ order.email }}</a><br>
                    Genehmiger:
                    <a v-if="order.verifyeremail == '' && order.status == 1"><i>Keine Genehmigung erforderlich</i></a>
                    <a v-else-if="order.verifyeremail == '' && order.status == 0"><i>Noch nicht bearbeitet</i></a>
                    <a v-else class="color-primary">{{ order.verifyeremail }}</a>
                  </p>
                </div>
                <div class="col-sp-12 col-tb-12 col-sd-2 row-sp-12 pad-top-16">
                  <span class="label tiny">
                    {{ renderTimestampForJS(order.timestamp) }} Uhr
                  </span>
                </div>
                <div class="col-sp-12 col-tb-12 col-sd-3 row-sp-12 t-right pad-top-16 pad-right-8">
                  <button class="button primary tiny mar-right-8" @click="getOrdersFromPortalById(order.id)">
                    Details
                  </button>
                  <button class="button black tiny" @click="deleteOrder(order.id)" v-if="mendoDelete == 0">
                    Löschen
                  </button>
                  <button class="button black tiny" @click="restoreOrder(order.id)" v-else>
                    Wiederherstellen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <transition name="modal" id="OrderDetailsModal" style="position: fixed;">
    <div class="vueModal" v-if="OrderDetailsModal">
      <div class="vueModalOuter" @click="OrderDetailsModal = false">
        <div class="vueModalInner">
          <h6 class="pad-4 t-center f-bold">
            Bestellungsdetails
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <p class="pad-16">
              <b>Austausch: </b>
              <span v-if="orderDetails.austausch == 1">Ja</span>
              <span v-else>Nein</span>
              <br>
              <b>Grund:</b> {{ orderDetails.reason }}<br>
              <b>Nachricht Antragsteller:</b> {{ orderDetails.message }}<br>
              <b>Neues Gerät:</b> {{ deviceName }}<br>
              <b>Vertrag: </b>{{ orderDetails.vertrag }}<br>
              <b v-if="orderDetails.zubehoer">Zubehör:</b><br> {{ orderDetails.zubehoer }}<br>

              <b>Nachricht Genehmiger:</b> {{ orderDetails.order_comment }}<br><br>

              <b>Unternehmen:</b> {{ orderDetails.company }}<br>
              <b>Betroffener Nutzer (Name):</b> {{ orderDetails.name }}<br>
              <b>Betroffener Nutzer (E-Mail):</b> {{ orderDetails.email }}<br>
              <b>Kostenstelle:</b> {{ orderDetails.kostenstelle }}<br>
              <b>Rufnummer:</b> {{ orderDetails.geraetphonenumber }}<br>
              <b>Kaufdatum Altgerät:</b> {{ orderDetails.contractfrom }}<br><br>

              <b>Firma:</b> {{ orderDetails.company }}<br>
              <b>Straße:</b> {{ orderDetails.street }}<br>
              <b>Hausnr.:</b> {{ orderDetails.hnr }}<br>
              <b>PLZ:</b> {{ orderDetails.plz }}<br>
              <b>Stadt:</b> {{ orderDetails.city }}<br>
              <b>Land:</b> {{ orderDetails.country }}<br>
              <b>Handelt es sich um eine Privatadresse?:</b>
              <span v-if="orderDetails.privatadress == 1">Ja</span>
              <span v-else>Nein</span>
              <br><br>
              <b>Besteller-Name:</b> {{ orderDetails.contactname }}<br>
              <b>Besteller-E-Mail: </b> {{ orderDetails.contactemail }}<br>
            </p>
          </div>
          <div class="pad-4">
            <button class="button black fluid" @click="OrderDetailsModal = false">
              Schließen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";

export default {
  name: "orders",
  title: "Portal Orders",
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      portal: '',
      orders: [],
      searchString: '',
      mendoDelete: 0,
      orderDetails: '',
      OrderDetailsModal: false,
      deviceName: ''
    }
  },
  computed:  {
    ordersFiltered(){
      return this.orders.filter(a => {
        let result = false
        for (const [key, value] of Object.entries(a)) {
          try {
            if(value.toString().toLowerCase().includes(this.searchString.toString().toLowerCase())) result = true
          }catch (e) {}
        }
        return result
      })
    },
  },
  mounted() {
    this.config.loading = false
    this.getPortalById()
    this.getOrders()
  },
  methods: {
    getDeviceName(tarif){
      aSyncData('kundenportale/getDeviceName', {id: this.$route.params.id, tarif: tarif})
          .then(r => {
            if(r.status === 250) {
              this.deviceName = r.data
            }
          })
    },
    getOrders(){
      this.orders = [];
      aSyncData('kundenportale/getOrders', {id: this.$route.params.id, mendoDelete: this.mendoDelete})
          .then(r => {
            if(r.status === 250) {
              this.orders = r.data
            }
          })
    },
    getOrdersFromPortalById(orderid){
      this.orderDetails = '';
      this.OrderDetailsModal = true;
      aSyncData('kundenportale/getOrdersFromPortalById', {id: this.$route.params.id, orderid: orderid})
          .then(r => {
            if(r.status === 250) {
              this.orderDetails = r.data
              this.getDeviceName(r.data.tarif)
            }
          })
    },
    renderTimestampForJS(date){
      const jsTimestamp = date.split(' ')
      const jsDate = jsTimestamp[0].split('-')
      const jsTime = jsTimestamp[1].split(':')
      return jsDate[2] + '.' + jsDate[1] + '.' + jsDate[0] + ' ' + jsTime[0] + ':' + jsDate[1]
    },
    getPortalById() {
      aSyncData('kundenportale/getPortalById', {id: this.$route.params.id})
          .then(r => {
            if(r.status === 250) {
              this.portal = r.data
            }
          })
    },
    deleteOrder(orderId){
      aSyncData('kundenportale/deleteOrder',{id: this.$route.params.id, orderId: orderId, delete: 1})
          .then(r => {
            this.getOrders()
          })
          .catch(e => {})
    },
    restoreOrder(orderId){
      aSyncData('kundenportale/deleteOrder',{id: this.$route.params.id, orderId: orderId, delete: 0})
          .then(r => {
            this.getOrders()
          })
          .catch(e => {})
    }
  }
}
</script>

<style scoped>
#orders h6{
  font-size: 0.85em;
}
#orders p,
#orders a{
  font-size: 0.9em;
  margin-top: 4px;
}
</style>