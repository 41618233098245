<template>
  <header>
    <div class="grid-12">
      <div class="col-sp-4 row-sp-12">
        <div class="pad-top-12 pad-left-8" style="cursor: pointer;" @click="menuModal = true">
          <svg fill="none" height="32" viewBox="0 0 24 24" width="40" xmlns="http://www.w3.org/2000/svg"><path d="M2 5.99519C2 5.44556 2.44556 5 2.99519 5H11.0048C11.5544 5 12 5.44556 12 5.99519C12 6.54482 11.5544 6.99039 11.0048 6.99039H2.99519C2.44556 6.99039 2 6.54482 2 5.99519Z" fill="currentColor" /><path d="M2 11.9998C2 11.4501 2.44556 11.0046 2.99519 11.0046H21.0048C21.5544 11.0046 22 11.4501 22 11.9998C22 12.5494 21.5544 12.9949 21.0048 12.9949H2.99519C2.44556 12.9949 2 12.5494 2 11.9998Z" fill="currentColor" /><path d="M2.99519 17.0096C2.44556 17.0096 2 17.4552 2 18.0048C2 18.5544 2.44556 19 2.99519 19H15.0048C15.5544 19 16 18.5544 16 18.0048C16 17.4552 15.5544 17.0096 15.0048 17.0096H2.99519Z" fill="currentColor" /></svg>
        </div>
      </div>
      <div class="col-sp-4 row-sp-12">
        <div class="t-center display-Mobile pad-8 pad-top-12">
          <router-link to="/dashboard">
            <img src="assets/images/mendo-consult-logo.png" class="width-80">
          </router-link>
        </div>
      </div>
      <div class="col-sp-4 row-sp-12">
        <div class="t-right pad-8">
          <div style="display: inline-block; cursor: pointer">
            <router-link to="/profil">
              <img style="vertical-align: -6px;" :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-32 circular">
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </header>
  <nav id="sideBar" class="display-Dektop">
    <div class="pad-8">
      <router-link to="/dashboard">
        <img src="assets/images/mendo-consult-logo.png" class="responsive-img">
      </router-link>
    </div>
    <ul>
      <li>
        <router-link to="/dashboard" active-class="active">
          <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none" /><path d="M6 26h16v-20h-16v20zm0 16h16v-12h-16v12zm20 0h16v-20h-16v20zm0-36v12h16v-12h-16z" /></svg>
          <span>Dashboard</span>
        </router-link>
      </li>
      <li>
        <router-link to="/nutzerverwaltung" active-class="active">
          <svg enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M9,9c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3S9,10.7,9,9z M12,14c-4.6,0-6,3.3-6,3.3V19h12v-1.7C18,17.3,16.6,14,12,14z   " /></g><g><g><circle cx="18.5" cy="8.5" r="2.5" /></g><g><path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" /></g></g><g><g><circle cx="18.5" cy="8.5" r="2.5" /></g><g><path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" /></g></g><g><g><circle cx="5.5" cy="8.5" r="2.5" /></g><g><path d="M5.5,13c1.2,0,2.1,0.3,2.8,0.8c-2.3,1.1-3.2,3-3.2,3.2l0,0.1H1v-1.3C1,15.7,2.1,13,5.5,13z" /></g></g></svg>
          <span>Benutzerverwaltung</span>
        </router-link>
      </li>
      <li>
        <router-link to="/kundenportale" active-class="active">
          <svg id="Layer_1" style="enable-background:new 0 0 91 91;" version="1.1" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M52.7,19.1H19.9v53.2h32.8V19.1z M34.7,62.2h-3.4v-6.2h3.4V62.2z M34.7,49.7h-3.4v-6.2h3.4V49.7z M34.7,37.3h-3.4v-6.2h3.4   V37.3z M45,62.2h-3.4v-6.2H45V62.2z M45,49.7h-3.4v-6.2H45V49.7z M45,37.3h-3.4v-6.2H45V37.3z" /><path d="M75.2,65.6V40.9l-19.7,0v31.4h13C72.2,72.3,75.2,69.3,75.2,65.6z M67.6,62.3H61V59h6.6V62.3z M67.6,54H61v-3.4h6.6V54z" /></g></svg>
          <span>Kundenportale</span>
        </router-link>
      </li>
      <li>
        <router-link to="/profil" active-class="active">
          <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97L29 4.84c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97L9.9 10.1c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31C9.06 22.69 9 23.34 9 24s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zM24 31c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" /></svg>
          <span>Einstellungen</span>
        </router-link>
      </li>
      <li>
        <a style="cursor: pointer;" @click="signOutModal = true" active-class="active">
          <svg id="svg8" version="1.1" viewBox="0 0 12.7 12.7" width="48" xmlns="http://www.w3.org/2000/svg" xmlns:cc="http://creativecommons.org/ns#" xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg"><g id="layer1" transform="translate(0,-284.3)"><path d="M 7.0555553,285.71111 H 2.1166666 v 9.87779 h 4.938889 v -0.70557 H 2.8222222 v -8.46667 h 4.2333334 z" id="path12" style="fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.7055555px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" /><path d="M 4.2333334,289.94445 H 9.172222 l -2.1166667,-2.11667 0.7055557,-0.70555 3.527778,3.52776 -3.527778,3.52779 -0.7055554,-0.70555 2.1166666,-2.11666 H 4.2333334 Z" id="path822" style="opacity:1;vector-effect:none;fill:#000000;fill-opacity:1;stroke:none;stroke-width:0.7055555px;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-dashoffset:0;stroke-opacity:1" /></g></svg>
          <span>Ausloggen</span>
        </a>
      </li>
    </ul>
  </nav>
  <transition name="modal" id="sideMenu">
    <div class="vueModal" v-if="menuModal">
      <div class="vueModalOuter" @click="menuModal = false">
        <div class="menuContent">
          <div class="t-center pad-8">
            <router-link to="/dashboard">
              <img src="assets/images/mendo-consult-logo.png" class="width-150">
            </router-link>
          </div>
          <h5>Allgemein</h5>
          <ul class="navTop pad-4" @click="menuModal = false">
            <li>
              <router-link to="/dashboard" active-class="active">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48h-48z" fill="none" /><path d="M6 26h16v-20h-16v20zm0 16h16v-12h-16v12zm20 0h16v-20h-16v20zm0-36v12h16v-12h-16z" /></svg>
                Dashboard
              </router-link>
            </li>
            <li>
              <router-link to="/nutzerverwaltung" active-class="active">
                <svg enable-background="new 0 0 24 24" id="Layer_1" version="1.0" viewBox="0 0 24 24" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M9,9c0-1.7,1.3-3,3-3s3,1.3,3,3c0,1.7-1.3,3-3,3S9,10.7,9,9z M12,14c-4.6,0-6,3.3-6,3.3V19h12v-1.7C18,17.3,16.6,14,12,14z   " /></g><g><g><circle cx="18.5" cy="8.5" r="2.5" /></g><g><path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" /></g></g><g><g><circle cx="18.5" cy="8.5" r="2.5" /></g><g><path d="M18.5,13c-1.2,0-2.1,0.3-2.8,0.8c2.3,1.1,3.2,3,3.2,3.2l0,0.1H23v-1.3C23,15.7,21.9,13,18.5,13z" /></g></g><g><g><circle cx="5.5" cy="8.5" r="2.5" /></g><g><path d="M5.5,13c1.2,0,2.1,0.3,2.8,0.8c-2.3,1.1-3.2,3-3.2,3.2l0,0.1H1v-1.3C1,15.7,2.1,13,5.5,13z" /></g></g></svg>
                Benutzerverwaltung
              </router-link>
            </li>
            <li>
              <router-link to="/kundenportale" active-class="active">
                <svg id="Layer_1" style="enable-background:new 0 0 91 91;" version="1.1" viewBox="0 0 91 91" xml:space="preserve" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><g><path d="M52.7,19.1H19.9v53.2h32.8V19.1z M34.7,62.2h-3.4v-6.2h3.4V62.2z M34.7,49.7h-3.4v-6.2h3.4V49.7z M34.7,37.3h-3.4v-6.2h3.4   V37.3z M45,62.2h-3.4v-6.2H45V62.2z M45,49.7h-3.4v-6.2H45V49.7z M45,37.3h-3.4v-6.2H45V37.3z" /><path d="M75.2,65.6V40.9l-19.7,0v31.4h13C72.2,72.3,75.2,69.3,75.2,65.6z M67.6,62.3H61V59h6.6V62.3z M67.6,54H61v-3.4h6.6V54z" /></g></svg>
                Kundenportale
              </router-link>
            </li>
            <li>
              <router-link to="/profil" active-class="active">
                <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg"><path d="M0 0h48v48H0z" fill="none" /><path d="M38.86 25.95c.08-.64.14-1.29.14-1.95s-.06-1.31-.14-1.95l4.23-3.31c.38-.3.49-.84.24-1.28l-4-6.93c-.25-.43-.77-.61-1.22-.43l-4.98 2.01c-1.03-.79-2.16-1.46-3.38-1.97L29 4.84c-.09-.47-.5-.84-1-.84h-8c-.5 0-.91.37-.99.84l-.75 5.3c-1.22.51-2.35 1.17-3.38 1.97L9.9 10.1c-.45-.17-.97 0-1.22.43l-4 6.93c-.25.43-.14.97.24 1.28l4.22 3.31C9.06 22.69 9 23.34 9 24s.06 1.31.14 1.95l-4.22 3.31c-.38.3-.49.84-.24 1.28l4 6.93c.25.43.77.61 1.22.43l4.98-2.01c1.03.79 2.16 1.46 3.38 1.97l.75 5.3c.08.47.49.84.99.84h8c.5 0 .91-.37.99-.84l.75-5.3c1.22-.51 2.35-1.17 3.38-1.97l4.98 2.01c.45.17.97 0 1.22-.43l4-6.93c.25-.43.14-.97-.24-1.28l-4.22-3.31zM24 31c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z" /></svg>
                Einstellungen
              </router-link>
            </li>
          </ul>
          <div class="footer bgcolor-primary">
            <div class="grid-12">
              <div class="col-sp-2 row-sp-12 pad-4">
                <img :src="config.projectURL + 'assets/images/users/'+user.avatar" class="image width-40 circular">
              </div>
              <div class="col-sp-9 row-sp-12 pad-4">
                <h3>{{ user.firstname }} {{ user.lastname }}</h3>
                <h4>{{ user.email }}</h4>
              </div>
              <div class="col-sp-1 row-sp-12 t-right" style="padding-top: 16px;">
                <img style="cursor: pointer; filter: brightness(50);" src="assets/images/icons/sign-out.svg" class="width-24" @click="signOutModal = true">
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="signOutModal" style="position: fixed;">
    <div class="vueModal" v-if="signOutModal">
      <div class="vueModalOuter" @click="signOutModal = false">
        <div class="vueModalInner">
          <h6 class="pad-4 t-center f-bold">
            Möchtest du dich wirklich ausloggen?
          </h6>
          <div class="pad-8" />
          <div class="pad-4">
            <button class="button primary fluid" @click="auth.logout();signOutModal = false">
              Abmelden
            </button>
          </div>
          <div class="pad-4">
            <button class="button black fluid" @click="signOutModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {authStore} from "@/store/authStore";
import {userStore} from "@/store/userStore";
import {configStore} from "@/store/configStore";

export default {
  name: "NavBar",
  setup(){
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data() {
    return {
      menuModal: false,
      notificationCount: 0,
      signOutModal: false,
    }
  },
  mounted() {
  },
  methods:{
  }
}
</script>

<style scoped>
header{
  position: fixed;
  width: 100%;
  z-index: 98;
  top: 0px;
  background-color: #ffffff;
}
header img.image{
  border: 2px solid #ffffff;
}
header .menubtn{
  padding: 4px;
  cursor: pointer;
  filter: invert(1);
}
header .menubtn img{
  filter: invert(1);
}
#sideBar{
  position: fixed;
  z-index: 99;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  max-width: 100px;
  background-color: #FFFFFF;
}
#sideBar ul{
  padding: 0;
  margin: 40px 0 0 0;
}
#sideBar ul li{
  text-align: center;
  padding: 0;
  margin: 0 0 24px 0;
}
#sideBar ul li a{
  display: block;
  border-left: 4px solid transparent;
  transition: 0.3s;
  padding: 8px 0;
  position: relative;
}
#sideBar ul li a.active{
  border-left: 4px solid var(--theme-mendo-primary);
}
#sideBar ul li a:hover{
  border-left: 4px solid var(--theme-mendo-primary);
  background-color: var(--theme-mendo-primary);
}
#sideBar ul li svg,
#sideBar ul li img{
  width: 32px;
  opacity: 0.6;
}
#sideBar ul li a:hover svg,
#sideBar ul li a:hover img{
  filter: invert(1);
  opacity: 1;
}
#sideBar ul li span{
  visibility: hidden;
  left: 0;
  top: 0;
  transition: 0.3s;
  position: absolute;
  color: #ffffff;
  padding: 17px 16px 17px 8px;
  font-weight: bold;
  background-color: var(--theme-mendo-primary);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
#sideBar ul li a:hover span{
  visibility: visible;
  left: 94px;
}

#sideMenu{
  position: fixed;
}
#sideMenu .menuContent{
  width: 100%;
  max-width: 300px;
  position: fixed;
  left: 0;
  background-color: #ffffff;
  height: 100vh;
  top: 0;
  padding: 16px;
  padding-top: 8px;
  z-index: 99;
}
#sideMenu .menuContent h1{
  font-size: 2em;
  padding-left: 8px;
  padding-bottom: 16px;
}
#sideMenu .menuContent h5{
  text-transform: uppercase;
  font-size: 0.8em;
  color: rgba(0,0,0,0.4);
  font-weight: 800;
  margin-top: 32px;
}
#sideMenu .menuContent .navTop{
  padding: 0;
  margin: 0;
  padding-top: 16px;
}
#sideMenu .menuContent .navTop li{
  list-style: none;
  display: block;
  margin-bottom: 24px;
}
#sideMenu .menuContent .navTop li a{
  text-decoration: none;
  display: block;
  color: var(--second-bg-color);
  font-weight: 500;
  letter-spacing: 1px;
  font-size: 1.2em;
  transition: 0.3s;
}
#sideMenu .menuContent .navTop li a img,
#sideMenu .menuContent .navTop li a svg{
  width: 28px;
  vertical-align: -6px;
}
#sideMenu .menuContent .navTop li a button{
  float: right;
  margin-top: -4px;
}
#sideMenu .menuContent .navTop li a:hover,
#sideMenu .menuContent .navTop li a.active{
  color: var(--theme-mendo-primary);
}
#sideMenu .menuContent .footer{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 300px;
  padding: 16px;
}
#sideMenu .menuContent .footer img{
  vertical-align: middle;
}
#sideMenu .menuContent .footer h3{
  font-size: 1em;
  font-weight: 700;
  color: #ffffff;
}
#sideMenu .menuContent .footer h4{
  font-size: 1em;
  font-weight: 300;
  color: rgba(255,255,255,0.6);
}
.desktopView{
  display: inline-block;
}
@media (max-width: 768px) {
  .desktopView{
    display: none;
  }
  header{
    background-color: #FAFAFA;
  }
}

</style>