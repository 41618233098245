<template>
  <main class="content-1400 page-content">
    <div id="page-dashboard">
      <div class="pad-4" />
      <div class="content-1200">
        <div class="grid-12">
          <div class="col-sp-12 col-tb-6 row-sp-12 t-left pad-8">
            <router-link to="/kundenportale" class="button primary">
              <i class="arrow left icon" />Zurück zur Übersicht
            </router-link>
          </div>
          <div class="col-sp-12 col-tb-6 row-sp-12 t-right pad-8">
            <router-link class="button black mar-right-8" :to="'/kundenportale/orders/'+portal.id">
              Bestellungen
            </router-link>
            <router-link class="button black" :to="'/kundenportale/usermanagement/'+portal.id">
              Usermanagement
            </router-link>
          </div>
        </div>
        <div class="pad-8">
          <div class="bgcolor-white br-10 pad-24">
            <div class="grid-16">
              <div class="col-sp-4 col-tb-2 row-sp-16 pad-8 pad-right-16 t-center">
                <img style="max-height: 55px; width: auto;" class="responsive-img pad-8" :src="'assets/images/portale/'+portal.company_logo">
              </div>
              <div class="col-sp-12 col-tb-6 row-sp-16 pad-16">
                <h2 class="ui header mar-left-12">
                  {{ portal.company_name }}
                  <div class="color-primary">
                    Kundenportal
                  </div>
                </h2>
              </div>
              <div class="col-sp-16 col-tb-8 row-sp-16 t-right">
                <div class="pad-4" style="display:inline-block;">
                  <button class="button primary" @click="profileModal = true;" style="margin-right: 8px">
                    <i class="mobile alternate icon" />
                    Profile verwalten
                  </button>
                  <button class="button primary" @click="tarifModal = true;">
                    <i class="mobile alternate icon" />
                    Produkte verwalten
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-16 pad-4">
          <div class="col-sp-16 row-sp-16 pad-4">
            <div class="bgcolor-white pad-24 br-10">
              <form class="ui form" method="POST" @submit.prevent="edit()" enctype="multipart/form-data">
                <div class="grid-16">
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Unternehmensname</label>
                      <input type="text" name="companyname" placeholder="Unternehmensname" required v-model="portal.company_name">
                    </div>
                    <div class="pad-16" />
                    <div class="field">
                      <label>Link zum Portal</label>
                      <input type="text" name="portaldomain" placeholder="Portal Link" required v-model="portal.portal_domain">
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16 t-center">
                    <img style="max-height: 55px; width: auto;" class="responsive-img pad-8" :src="[selectLogoData ? tempImg : 'assets/images/portale/'+portal.company_logo]">
                    <div class="pad-4" />
                    <label for="logoUpload" class="button black fluid">
                      Logo ändern
                    </label>
                    <input id="logoUpload" style="display: none;" type="file" ref="selectLogoData" @change="selectLogoFile" accept="image/*">
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Hauptfarbe</label>
                      <input type="text" placeholder="#FFFFFF" name="primarycolor" required v-model="portal.primary_color" :style="'border-bottom:' +portal.primary_color+' 6px solid;'">
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Zweitfarbe</label>
                      <input type="text" placeholder="#FFFFFF" name="secondarycolor" required v-model="portal.secondary_color" :style="'border-bottom:' +portal.secondary_color+' 6px solid;'">
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Info (Technischer Support)</label>
                      <textarea rows="4" name="tsupporttext" placeholder="Infotext" required v-model="portal.tsupporttext" />
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Info (Allgemeiner Support)</label>
                      <textarea rows="4" name="asupporttext" placeholder="Infotext" required v-model="portal.asupporttext" />
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Willkommenstext (Startseite)</label>
                      <textarea rows="4" name="welcomettext" placeholder="Willkommenstext" required v-model="portal.welcomettext" />
                    </div>
                  </div>
                  <div class="col-sp-16 col-tb-8 row-sp-16 pad-16">
                    <div class="field">
                      <label>Designbrand</label>
                      <select name="whitelabel" v-model="portal.whitelabel">
                        <option value="0">
                          Mendobrand
                        </option>
                        <option value="1">
                          Whitelabel
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="col-sp-16 t-right row-sp-16">
                    <div class="pad-8" />
                    <button type="submit" class="button primary">
                      <i class="save icon" />
                      Änderung speichern
                    </button>
                  </div>
                </div>
              </form>
              <div v-if="status == 'ok'" class="pad-16 t-center">
                <span class="label green">Änderungen erfolgreich gespeichert</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
  <transition name="modal" id="tarifModal" style="position: fixed;">
    <div class="vueModal" v-if="tarifModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Produkte bearbeiten
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div v-if="tarifId == ''">
              <div class="pad-24 mar-top-4 br-10" style="border: #00000040 2px dashed; cursor: pointer;" @click="createProductModal = true; tarifModal = false;">
                <h6 class="t-center">
                  <b>Neues Produkt erstellen</b>
                </h6>
              </div>
              <div v-for="tarif in tarife" :key="tarif.id" class="pad-24 mar-top-4 br-10 grid-12" style="background-color: #00000007;">
                <div class="col-sp-12 col-tb-8 row-sp-12">
                  <h6>
                    <b>Produkt {{ tarif.tarif_number }}</b> -
                    <span class="label tiny">{{ tarif.tarif_name }}</span>
                  </h6>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
                  <button class="primary tiny button" @click="selectTarif(tarif.id)">
                    Bearbeiten
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <button class="black tiny button mar-bottom-16" @click="tarifId = ''">
                    Zurück
                  </button>
                </div>
                <div class="col-sp-6 row-sp-12 t-right">
                  <a @click="deleteProductModal = true;" style="text-decoration: underline; cursor: pointer; font-size: 0.9em;">Produkt löschen</a>
                </div>
              </div>
              <form @submit.prevent="saveTarif" enctype="multipart/form-data">
                <div class="field">
                  <label>Produktname</label>
                  <input type="text" name="tarifname" placeholder="Produktname" required v-model="tarifDetails.tarif_name">
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Gerätename</label>
                  <input type="text" name="tarifdevice" placeholder="Gerätename" required v-model="tarifDetails.tarif_device">
                </div>
                <div class="pad-4" />
                <div class="field" v-if="portal.tarif_categories == 1">
                  <label>Kategorie</label>
                  <select name="tarif_category" v-model="tarifDetails.tarif_category">
                    <option value="" disabled selected>
                      Kategorie
                    </option>
                    <option value="Simkarte">
                      Simkarte
                    </option>
                    <option value="Smartphone">
                      Smartphone
                    </option>
                    <option value="Outdoor Phone">
                      Outdoor Phone
                    </option>
                    <option value="Tablets">
                      Tablets
                    </option>
                  </select>
                </div>
                <div class="pad-16 t-center">
                  <img :src="'assets/images/tarife/'+tarifDetails.tarif_img" class="responsive-img" alt="Kein Bild vorhanden." style="max-width: 120px;">
                  <div class="pad-4" />
                  <input type="file" ref="selectFileData" @change="selectFile" accept="image/*">
                </div>
                <div class="field">
                  <label>Beschreibung</label>
                  <textarea rows="4" name="tarifdescription" placeholder="Beschreibung" required v-model="tarifDetails.tarif_description" />
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Profile</label>
                  <select v-model="selectedProfile" multiple>
                    <option :value="item.id" v-for="item in profiles" :key="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                  <p style="font-size: 0.6em;">
                    Um mehrere Profile auszuwählen, bitte bei der Auswahl [Command] bzw. [Strg] gedrückt halten
                  </p>
                </div>
                <div class="pad-4" />
                <div class="field" style="padding: 8px; background-color: #00000010">
                  <label>Zubehör:</label>
                  <div class="pad-4" />
                  <div v-for="zubehoerItem in zubehoer" :key="zubehoerItem.id">
                    <div class="grid-12" v-if="zubehoerItem.id === zubehoerId" style="margin: 8px 0;">
                      <div class="col-sp-5 row-sp-12">
                        <div class="field">
                          <label>Zubehörname</label>
                          <input type="text" placeholder="Zubehörname" v-model="zubehoerName">
                        </div>
                      </div>
                      <div class="col-sp-4 row-sp-12 t-right">
                        <div class="field">
                          <label>Preis (€)</label>
                          <input type="number" step="0.01" placeholder="Preis" v-model="zubehoerPrice">
                        </div>
                      </div>
                      <div class="col-sp-3 row-sp-12 t-right">
                        <button type="button" class="black tiny button mar-top-16" @click="saveZubehoer()">
                          Speichern
                        </button>
                      </div>
                      <div class="col-sp-12 row-sp-12">
                        <a @click="deleteZubehoer()" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;">Zubehör löschen</a>
                      </div>
                    </div>
                    <div v-else class="grid-12">
                      <div class="col-sp-5 row-sp-12">
                        {{ zubehoerItem.name }}
                      </div>
                      <div class="col-sp-4 row-sp-12 t-right">
                        {{ (zubehoerItem.price).toFixed(2) }}€
                      </div>
                      <div class="col-sp-3 row-sp-12 t-right">
                        <button type="button" class="black tiny button" @click="zubehoerId = zubehoerItem.id; zubehoerName = zubehoerItem.name; zubehoerPrice = zubehoerItem.price">
                          Bearbeiten
                        </button>
                      </div>
                    </div>
                  </div>
                  <p class="t-center" style="font-size: 0.8em; color: #00000080;" v-if="zubehoer?.length === 0">
                    Kein Zubehör zu diesem Produkt vorhanden
                  </p>
                  <div v-if="!createZubehoerForm" class="t-center pad-4">
                    <button type="button" class="black tiny button" @click="createZubehoerForm = true;">
                      Zubehör erstellen
                    </button>
                  </div>
                  <div v-else class="grid-12">
                    <div class="col-sp-5 row-sp-12">
                      <div class="field">
                        <label>Zubehörname</label>
                        <input type="text" placeholder="Zubehörname" v-model="zubehoerName">
                      </div>
                    </div>
                    <div class="col-sp-4 row-sp-12 t-right">
                      <div class="field">
                        <label>Preis (€)</label>
                        <input type="number" step="0.01" placeholder="Preis" v-model="zubehoerPrice">
                      </div>
                    </div>
                    <div class="col-sp-3 row-sp-12 t-right">
                      <button type="button" style="margin-top: 32px;" class="black tiny button" @click="createZubehoer()">
                        Erstellen
                      </button>
                    </div>
                    <div class="col-sp-12 row-sp-12">
                      <a @click="zubehoerName = ''; zubehoerPrice = '';createZubehoerForm = false" style="text-decoration: underline; font-size: 0.8em; cursor: pointer;">Abbrechen</a>
                    </div>
                  </div>
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Preis (€)</label>
                  <input type="number" step="0.01" name="tarifprice" placeholder="0.00" required v-model="tarifDetails.tarif_price">
                </div>
                <div class="pad-8" />
                <button type="submit" class="button primary fluid">
                  Änderungen Speichern
                </button>
              </form>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button black fluid" @click="tarifModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" id="profileModal" style="position: fixed;">
    <div class="vueModal" v-if="profileModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Profile bearbeiten
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div v-if="profileId == ''">
              <div class="pad-24 mar-top-4 br-10" style="border: #00000040 2px dashed; cursor: pointer;" @click="createProfileModal = true; profileModal = false;">
                <h6 class="t-center">
                  <b>Neues Profil erstellen</b>
                </h6>
              </div>
              <div v-for="profile in profiles" :key="profile.id" class="pad-24 mar-top-4 br-10 grid-12" style="background-color: #00000007;">
                <div class="col-sp-12 col-tb-8 row-sp-12">
                  <h6>
                    <b>{{ profile.name }}</b>
                  </h6>
                </div>
                <div class="col-sp-12 col-tb-4 row-sp-12 t-right">
                  <button class="primary tiny button" @click="selectProfile(profile.id)">
                    Bearbeiten
                  </button>
                </div>
              </div>
            </div>
            <div v-else>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <button class="black tiny button mar-bottom-16" @click="profileId = ''">
                    Zurück
                  </button>
                </div>
                <div class="col-sp-6 row-sp-12 t-right">
                  <a @click="deleteProfileModal = true;" style="text-decoration: underline; cursor: pointer; font-size: 0.9em;">Profil löschen</a>
                </div>
              </div>
              <form @submit.prevent="saveProfile" enctype="multipart/form-data">
                <div class="field">
                  <label>Profilname</label>
                  <input type="text" name="tarifname" placeholder="Profilname" required v-model="profileDetails.name">
                </div>
                <div class="pad-4" />
                <div class="pad-8" />
                <button type="submit" class="button primary fluid">
                  Änderungen Speichern
                </button>
              </form>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button black fluid" @click="profileModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" style="position: fixed;">
    <div class="vueModal" v-if="createProductModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Produkt erstellen
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <button class="black tiny button mar-bottom-16" @click="createProductModal = false; tarifModal = true;">
                    Zurück
                  </button>
                </div>
              </div>
              <form @submit.prevent="createProduct()" enctype="multipart/form-data">
                <div class="field">
                  <label>Produktname</label>
                  <input type="text" name="tarifname" placeholder="Produktname" required v-model="tarif_name">
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Gerätename</label>
                  <input type="text" name="tarifdevice" placeholder="Gerätename" required v-model="tarif_device">
                </div>
                <div class="pad-4" />
                <div class="field" v-if="portal.tarif_categories == 1">
                  <label>Kategorie</label>
                  <select name="tarif_category" v-model="tarif_category">
                    <option value="" disabled selected>
                      Kategorie
                    </option>
                    <option value="Simkarte">
                      Simkarte
                    </option>
                    <option value="Smartphone">
                      Smartphone
                    </option>
                    <option value="Outdoor Phone">
                      Outdoor Phone
                    </option>
                    <option value="Tablets">
                      Tablets
                    </option>
                  </select>
                </div>
                <div class="t-center">
                  <input type="file" ref="selectFileData" @change="selectFile" accept="image/*">
                  <div class="pad-8" />
                </div>
                <div class="field">
                  <label>Beschreibung</label>
                  <textarea rows="4" name="tarifdescription" placeholder="Beschreibung" required v-model="tarif_description" />
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Profile</label>
                  <select v-model="selectedProfile" multiple>
                    <option :value="item.id" v-for="item in profiles" :key="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                  <p style="font-size: 0.6em;">
                    Um mehrere Profile auszuwählen, bitte bei der Auswahl [Command] bzw. [Strg] gedrückt halten
                  </p>
                </div>
                <div class="field">
                  <label>Zubehör:</label>
                  <p style="font-size: 0.8em; color: #00000080;">
                    Du musst erst das Produkt erstellen und kannst anschließend über "Produkt bearbeiten" Zubehör zum Produkt ergänzen.
                  </p>
                </div>
                <div class="pad-4" />
                <div class="field">
                  <label>Preis (€)</label>
                  <input type="number" step="0.01" name="tarifprice" placeholder="0.00" required v-model="tarif_price">
                </div>
                <div class="pad-8" />
                <button type="submit" class="button primary fluid">
                  Produkt erstellen
                </button>
              </form>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button black fluid" @click="createProductModal = false; tarifModal = true;">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" style="position: fixed;">
    <div class="vueModal" v-if="createProfileModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Profil erstellen
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div>
              <div class="grid-12">
                <div class="col-sp-6 row-sp-12">
                  <button class="black tiny button mar-bottom-16" @click="createProfileModal = false; profileModal = true;">
                    Zurück
                  </button>
                </div>
              </div>
              <form @submit.prevent="createProfile()" enctype="multipart/form-data">
                <div class="field">
                  <label>Profilname</label>
                  <input type="text" name="tarifname" placeholder="Profilname" required v-model="name">
                </div>
                <div class="pad-4" />
                <div class="pad-8" />
                <button type="submit" class="button primary fluid">
                  Profil erstellen
                </button>
              </form>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button black fluid" @click="createProfileModal = false; profileModal = true;">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" style="position: fixed;">
    <div class="vueModal" v-if="deleteProductModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Produkt löschen
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div>
              <p class="t-center">
                Möchtest du das Produkt wirklich löschen?
              </p>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button primary fluid" @click="deleteProduct()">
              Produkt löschen
            </button>
            <div class="pad-4" />
            <button class="button black fluid" @click="deleteProductModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>
  <transition name="modal" style="position: fixed;">
    <div class="vueModal" v-if="deleteProfileModal">
      <div class="vueModalOuter">
        <div class="vueModalInner" style="width: 100%; max-width: 500px;">
          <h6 class="pad-4 t-center f-bold pad-bottom-16">
            Profil löschen
          </h6>
          <div style="max-height: 300px; overflow-y: scroll;">
            <div>
              <p class="t-center">
                Möchtest du das Profil wirklich löschen?
              </p>
            </div>
          </div>
          <div class="pad-4 mar-top-16">
            <button class="button primary fluid" @click="deleteProfile()">
              Profil löschen
            </button>
            <div class="pad-4" />
            <button class="button black fluid" @click="deleteProfileModal = false">
              Abbrechen
            </button>
          </div>
        </div>
      </div>
    </div>
  </transition>

  <VueModal :show="logoModal" @close="logoModal = false" :approve="logoBlob ? (loadingLogo? 'Lädt...' : 'Speichern') : false" :approve-disabled="loadingLogo" @approve="saveLogo">
    <cropper-upload :init="true" :ratio="1.875" @cropped="c => this.logoBlob = c" />
  </VueModal>
</template>

<script>


import {authStore} from "@/store/authStore";
import {configStore} from "@/store/configStore";
import {userStore} from "@/store/userStore";
import {aSyncData} from "@/modules/main";
import CropperUpload from "@/components/images/cropperUpload";

export default {
  name: "edit",
  title: "Portal Edit",
  components: {
    CropperUpload
  },
  setup() {
    const auth = authStore()
    const config = configStore()
    const user = userStore()

    return {auth, config, user}
  },
  data(){
    return {
      portal: '',
      logo: '',
      logoModal: false,
      logoBlob: false,
      loadingLogo: false,
      TarifImgModal: false,
      TarifImgBlob: false,
      loadingTarifImg: false,
      tarifModal: false,
      profileModal: false,
      deleteProductModal: false,
      createProductModal: false,
      CreateTarifImgModal: false,
      createProfileModal: false,
      deleteProfileModal: false,
      tarifId: '',
      tarifId2: '',
      tarife: '',
      profiles: '',
      tarifDetails: '',
      status: '',
      tarif_name: '',
      tarif_device: '',
      tarif_description: '',
      tarif_category: '',
      tarif_price: '',
      name: '',
      profileId: '',
      profileDetails: '',
      selectedProfile: [],
      selectFileData: null,
      selectLogoData: null,
      tempImg: null,
      zubehoer: [],
      zubehoerId: null,
      zubehoerName: '',
      zubehoerPrice: '',
      createZubehoerForm: false,
    }
  },
  mounted() {
    this.config.loading = false
    this.getPortalById()
    this.getTarife()
    this.getProfile()


  },
  methods: {
    selectFile(event) {
      this.selectFileData = event.target.files[0];
    },
    selectLogoFile(event) {
      this.selectLogoData = event.target.files[0];
      this.tempImg = URL.createObjectURL(event.target.files[0]);

    },
    saveTarifImg() {
      this.loadingTarifImg = true
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('TarifImg', this.TarifImgBlob)
      fD.append('id', this.tarifId2)
      this.axios.post('kundenportale/saveTarifImg', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            if(response.status === 250){
              _this.TarifImgModal = false;
            }
          })
          .catch(e => {
            console.error(e)
          })
          .finally(() => {
            _this.loadingLogo = false
          })
    },
    edit(){
      console.log(this.selectLogoData)
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('company_name', this.portal.company_name)
      fD.append('portal_domain', this.portal.portal_domain)
      fD.append('primary_color', this.portal.primary_color)
      fD.append('secondary_color', this.portal.secondary_color)
      fD.append('tsupporttext', this.portal.tsupporttext)
      fD.append('asupporttext', this.portal.asupporttext)
      fD.append('welcomettext', this.portal.welcomettext)
      fD.append('whitelabel', this.portal.whitelabel)
      fD.append('Logo', this.selectLogoData);

      fD.append('id', this.$route.params.id)
      this.axios.post('kundenportale/edit', fD)
          .then(response => {
            if(response.status === 250){
              this.status = 'ok'
              _this.getPortalById()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    saveTarif(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('tarif_name', this.tarifDetails.tarif_name)
      fD.append('tarif_device', this.tarifDetails.tarif_device)
      fD.append('tarif_description', this.tarifDetails.tarif_description)
      fD.append('tarif_category', this.tarifDetails.tarif_category)
      fD.append('tarif_price', this.tarifDetails.tarif_price)
      fD.append('TarifImg', this.selectFileData);
      fD.append('profile', this.selectedProfile)
      fD.append('id', this.tarifId)
      this.axios.post('kundenportale/saveTarif', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.tarifId = ''
              _this.tarifModal = false
              _this.selectedProfile = []
              _this.selectFileData = null
              _this.getTarife()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    saveProfile(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('name', this.profileDetails.name)
      fD.append('id', this.profileId)
      this.axios.post('kundenportale/saveProfile', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.profileId = ''
              _this.profileModal = false
              _this.getProfile()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    createProduct(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('tarif_name', this.tarif_name)
      fD.append('tarif_device', this.tarif_device)
      fD.append('tarif_description', this.tarif_description)
      fD.append('tarif_category', this.tarif_category)
      fD.append('tarif_price', this.tarif_price)
      fD.append('portal', this.portal.id)
      fD.append('profile', this.selectedProfile)
      fD.append('TarifImg', this.selectFileData);
      this.axios.post('kundenportale/createProduct', fD, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.tarifId = ''
              _this.createProductModal = false
              _this.tarifModal = true
              _this.tarif_name = ''
              _this.tarif_device = ''
              _this.tarif_description = ''
              _this.tarif_category = ''
              _this.tarif_price = ''
              _this.selectedProfile = []
              _this.selectFileData = null
              _this.getTarife()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    createProfile(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('name', this.name)
      fD.append('portal', this.portal.id)
      this.axios.post('kundenportale/createProfile', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.tarifId = ''
              _this.createProfileModal = false
              _this.profileModal = true
              _this.name = ''
              _this.getProfile()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteProduct(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.tarifId)
      this.axios.post('kundenportale/deleteProduct', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.tarifId = ''
              _this.deleteProductModal = false
              _this.getTarife()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteProfile(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.profileId)
      this.axios.post('kundenportale/deleteProfile', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.profileId = ''
              _this.deleteProfileModal = false
              _this.getProfile()
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    getPortalById() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getPortalById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.portal = response.data
            }
          })
    },
    getTarife() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getTarife', fD)
          .then((response) => {
            if(response.status === 250){
              _this.tarife = response.data
            }
          })
    },
    getProfile() {
      const _this = this
      const fD = new FormData()
      fD.append('id', this.$route.params.id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getProfile', fD)
          .then((response) => {
            if(response.status === 250){
              _this.profiles = response.data
            }
          })
    },
    getZubehoer(id) {
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getZubehoer', fD)
          .then((response) => {
            if(response.status === 250){
              _this.zubehoer = response.data
            }
          })
    },
    saveZubehoer(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('name', this.zubehoerName)
      fD.append('price', this.zubehoerPrice)
      fD.append('id', this.zubehoerId)
      this.axios.post('kundenportale/saveZubehoer', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.zubehoerName = ''
              _this.zubehoerPrice = ''
              _this.zubehoerId = null
              _this.getZubehoer(this.tarifId)
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    deleteZubehoer(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('id', this.zubehoerId)
      this.axios.post('kundenportale/deleteZubehoer', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.zubehoerName = ''
              _this.zubehoerPrice = ''
              _this.zubehoerId = null
              _this.getZubehoer(this.tarifId)
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    createZubehoer(){
      const _this = this
      const fD = new FormData()
      fD.append('sessionID', this.auth.sessionID)
      fD.append('name', this.zubehoerName)
      fD.append('price', this.zubehoerPrice)
      fD.append('tarifId', this.tarifId)
      fD.append('portal', this.portal.id)
      this.axios.post('kundenportale/createZubehoer', fD)
          .then(response => {
            console.log(response)
            if(response.status === 250){
              this.status = 'ok'
              _this.zubehoerName = ''
              _this.zubehoerPrice = ''
              _this.createZubehoerForm = false
              _this.getZubehoer(this.tarifId)
            }else if(response.status === 251){
              this.status = 'error'
            }
          })
          .catch(e => {
            console.error(e)
          })
    },
    selectTarif(id){
      this.tarifId = id
      this.selectedProfile = []
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getTarifById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.tarifDetails = response.data
              _this.selectedProfile = response.data.profile.split(",")
              _this.getZubehoer(id)
            }
          })
    },
    selectProfile(id){
      this.profileId = id
      const _this = this
      const fD = new FormData()
      fD.append('id', id)
      fD.append('sessionID', this.auth.sessionID)
      this.axios.post('kundenportale/getProfileById', fD)
          .then((response) => {
            if(response.status === 250){
              _this.profileDetails = response.data
            }
          })
    }
  }
}
</script>

<style>
/*
  .cropper-container .cropper-crop-box,
  .cropper-container .cropper-drag-box.cropper-crop.cropper-modal{
    display: none!important;
  }
  */

</style>